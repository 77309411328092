export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'GM-US',
  hostName: 'gmus-analytics-prod.azurewebsites.net',
  environmentName: 'production',
  appName: 'GM-US Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '00458e01-9f2b-45f9-b4a7-d29c878e2cae'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1,2],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#1b365c', '#47ddfc', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://gmus-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://gmus-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://gmus-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/gm-us/favicon.ico',
  loginRedirectUrl:  'https://www.autopartners.net/gmentsso/idpssoinit?metaAlias=/vsprealm/idp&spEntityID=analytics.gmdealerdigital.com',
  logoutRedirectUrl: 'https://dealer.autopartners.net/portal/us/Pages/default.aspx',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview','WebsiteScorecard','TradeInOverview','ChatOverview','DigitalAdvertisingTier2Dashboard','DigitalAdvertisingDashboard']
};
